import { React, useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import api from '../../config/axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShowToast from "../../pages/admin/Toast";
import { useDispatch, useSelector } from "react-redux";
import { setAppointment } from "../../reducers/dashboardSlice";
import { Pagination, Stack, Skeleton, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { setUserData } from "../../reducers/userDetails";

const AppointmentList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [load, setLoad] = useState();
    const appointmentData = useSelector((state) => state.dashboard.data);
    let previousDate = new Date();
    previousDate = format(previousDate, 'yyyy-MM-dd');
    const [pages, setPages] = useState();
    const [perPage, setItemShow] = useState(10);
    const [pageReset, setPageReset] = useState(1);
    const [indexering, setIndexNum] = useState(1);
    let pageCount;

    async function appointments(perPage, page) {
        setLoad(true);
        pageCount = page;
        if (pageCount == null || pageCount == undefined) {
            pageCount = 1;
        }
        try {
            const response = await api.get(`/api/v1/appointments?page=${pageCount}&per_page=${perPage}&q[date_eq]=${previousDate}`);
            if (pageCount == 1) {
                setPageReset(1);
            }
            else {
                setPageReset(page);
            }
            const updatedData = response.data.data.items.map(item => {

                if (previousDate == item.date) {
                    const timeString = item.start_time;
                    const timeStringEnd = item.end_time;
                    const formattedTime = timeString.slice(11, 16);
                    const endTime = timeStringEnd.slice(11, 16);
                    return {
                        ...item,
                        start_time: formattedTime,
                        end_time: endTime
                    };
                }
            });
            dispatch(setAppointment([...updatedData]));
            setIndexNum(page);
            setPages(response.data.data.total_pages);
            setItemShow(response.data.data.per_page);
        }
        catch (error) {
            ShowToast(error.response.statusText, "error");
        }
        finally {
            setLoad(false);
        }
    }

    useEffect(() => {
        appointments();
    }, [])

    const moveTo = (patientId, apptId) => {
        navigate(`/users/${patientId}/visits/${apptId}`);
    }

    const goToDetailPage = (type, id) => {

        dispatch(setUserData([]));
        navigate(`/users/${type}/` + id);
    };

    return (
        <>
            <ToastContainer />
            <ol className="list-group card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    <strong>Today Appointments</strong>
                    <button onClick={() => navigate('/users/appointments')} className="btn">view all</button>
                </div>
                <Table className="bg-white rounded m-0">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Patient</th>
                            <th scope="col">Doctor</th>
                            <th scope="col">Slot</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!load && (
                            appointmentData.length > 0 ? (
                                appointmentData.map((item, i) => (
                                    <tr className="item" key={i}>
                                        <td className={`${item.status == 'Cancel' ? "disabled" : "item-name"}`} scope="row"><a onClick={() => { item.status != 'Cancel' && moveTo(item.patient_id, item.id) }}>{item.id}</a></td>
                                        <td className={`${item.status == 'Cancel' ? "disabled" : "item-name"} item-name`}><a onClick={() => goToDetailPage('patient', item.patient_id)}>{item.patient_first_name} {item.patient_last_name}</a></td>
                                        <td className={`${item.status == 'Cancel' ? "disabled" : "item-name"} item-name`}><a onClick={() => goToDetailPage('doctor', item.doctor_id)}>{item.doctor_first_name} {item.doctor_last_name}</a></td>
                                        <td className={`${item.status == 'Cancel' ? "disabled" : ""}`}>{item.start_time}-{item.end_time}</td>
                                        <td className={`${item.status == 'Cancel' ? "disabled" : ""}`}>{item.date}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td className="text-center" colSpan={5}>appointment doesn't exist</td>
                                </tr>
                            )
                        )
                        }
                        {load && <>
                            {Array.from({ length: 5 }, (_, i) => (
                                <tr key={i}>
                                    <td >
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td >
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td >
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td >
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td >
                                        <Skeleton animation="wave" />
                                    </td>
                                </tr>
                            ))}
                        </>}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={5}>
                                <Stack className="pagination" spacing={2}>
                                    <Pagination count={pages} page={pageReset} color="primary" onChange={(event, page) => appointments(perPage, page)} />
                                </Stack>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </ol >
        </>
    )
}

export default AppointmentList