import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../../scripts/utils/ProtectedRoute";
const AllUsers = React.lazy(() => import("./AllUsersBoundary"));
const Doctor = React.lazy(() => import("./DoctorBoundary"));
const Receptionist = React.lazy(() => import("./ReceptionBoundary"));
const UserDetails = React.lazy(() => import("./UserDetailsBoundary"));
const Patient = React.lazy(() => import("./PatientBoundary"));
const Appointment = React.lazy(() => import("./AppointmentBoundary"));
const Medicatons = React.lazy(() => import("./MedicineBoundary"));
const LeaveBoundary = React.lazy(() => import("./LeaveBoundary"));
const Schedule = React.lazy(() => import("./ScheduleBoundary"));
const VisitHistoryBoundary = React.lazy(() => import("./VisitHistoryBoundary"));
const Profile = React.lazy(() => import("./EditProfileBoundary"));
const LeaveDetailViewBoundary = React.lazy(() => import("./LeaveDetailViewBoundary"));

function LoadingIndicator() {
  return <div className="form-loader"></div>;
}

const UsersRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<AllUsers />} />
          </Suspense>
        }
      />
      <Route
        path=":id/visits/:id"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<VisitHistoryBoundary />} />
          </Suspense>
        }
      />
      <Route
        path="/doctors"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<Doctor />} />
          </Suspense>
        }
      />
      <Route
        path="/doctor/:id"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<UserDetails user="doctor" />} />
          </Suspense>
        }
      />
      <Route
        path="/receptionist"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<Receptionist />} />
          </Suspense>
        }
      />
      <Route
        path="/receptionist/:id"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<UserDetails user="receptionist" />} />
          </Suspense>
        }
      />
      <Route
        path="/patient"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<Patient />} />
          </Suspense>
        }
      />
      <Route
        path="/patient/:id"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<UserDetails user="patient" />} />
          </Suspense>
        }
      />
      <Route
        path="/appointments"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<Appointment />} />
          </Suspense>
        }
      />
      <Route
        path="/medications"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<Medicatons />} />
          </Suspense>
        }
      />
      <Route
        path="/leave"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<LeaveBoundary />} />
          </Suspense>
        }
      />
      <Route
        path="/leave/:id"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<LeaveDetailViewBoundary />} />
          </Suspense>
        }
      />
      <Route
        path="/schedule"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<Schedule />} />
          </Suspense>
        }
      />
      <Route
        path="/profile"
        element={
          <Suspense fallback={<LoadingIndicator />}>
            <ProtectedRoute component={<Profile />} />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default UsersRoutes;
