
import { configureStore } from "@reduxjs/toolkit";
import departmentSlice from "./reducers/departmentSlice";
import authSlice from "./reducers/authSlice";
import designationSlice from "./reducers/designationSlice";
import doctorSlice from "./reducers/doctorSlice";
import receptionSlice from "./reducers/receptionSlice";
import patientSlice from "./reducers/patientSlice";
import userDetailSlice from "./reducers/userDetails"
import forgotPasswordSlice from "./reducers/forgotPasswordSlice";
import updatePasswordSlice from "./reducers/updatePasswordSlice";
import allUsersSlice from "./reducers/allUsersSlice";
import loaderSlice from "./reducers/loaderSlice";
import dashboardSlice from "./reducers/dashboardSlice";
import appointmentSlice from "./reducers/appointmentSlice";
import medicineSlice from "./reducers/medicineSlice";
import organisationSettingSlice from "./reducers/organisationSettingSlice";
import userConfiguration from "./reducers/userConfiguration";
import graphSlice from "./reducers/graphs";
import serviceSlice from "./reducers/serviceSlice";
import dutyRoaster from "./reducers/roaster";
import filterSlice from "./reducers/filter";
import leaveSlice from "./reducers/leaveSlice";
import invoiceSlice from "./reducers/invoiceSlice";

const store = configureStore({
    reducer: {
        departments: departmentSlice,
        auth: authSlice,
        designation: designationSlice,
        doctor: doctorSlice,
        reception: receptionSlice,
        patient: patientSlice,
        userdetail: userDetailSlice,
        forgotpassword: forgotPasswordSlice,
        updatepassword: updatePasswordSlice,
        allusers: allUsersSlice,
        loader: loaderSlice,
        dashboard: dashboardSlice,
        appointment: appointmentSlice,
        medicine: medicineSlice,
        settings: organisationSettingSlice,
        userconfiguration: userConfiguration,
        graph: graphSlice,
        service: serviceSlice,
        roaster: dutyRoaster,
        filter: filterSlice,
        leave: leaveSlice,
        invoice: invoiceSlice
    }
})

export default store;