import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {},
    visitRecord: [],
    appointmentData: [],
    appointmentDate: { appointment: { first_date: '', last_date: '' } },
    assignedDoctors: [],
    load: true,
    patientDetailVisit: {},
    assignedReceptionist: [],
    userAssigned: [],
    loadVisitHistory: true
}

const userDetailSlice = createSlice({
    name: 'userdetail',
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state.data = action.payload;
        },
        setVisitRecord: (state, action) => {
            state.visitRecord = action.payload;
        },
        setAppointmentData: (state, action) => {
            state.appointmentData = action.payload;
        },
        setAppointmentDate: (state, action) => {
            state.appointmentDate = action.payload;
        },
        setAssignedDoctor: (state, action) => {
            state.assignedDoctors = action.payload;
        },
        setLoad: (state, action) => {
            state.load = action.payload;
        },
        setPatientDetailVisit: (state, action) => {
            state.patientDetailVisit = action.payload;
        },
        setAssignedReceptionist: (state, action) => {
            state.assignedReceptionist = action.payload;
        },
        setUserAssigned: (state, action) => {
            state.userAssigned = action.payload;
        },
        setLoadVisitHistory: (state, action) => {
            state.loadVisitHistory = action.payload;
        }
    },
});

export const { setUserData, setVisitRecord, setAppointmentData, setAppointmentDate, setAssignedDoctor, setLoad, setPatientDetailVisit, setAssignedReceptionist, setUserAssigned, setLoadVisitHistory } = userDetailSlice.actions;

export default userDetailSlice.reducer;