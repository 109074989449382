import React, { useRef, useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link, Navigate } from "react-router-dom";
import api from "../../config/axios";
import FeatureColumn from '../../components/auth/FeatureColumn';
import "../../client/scss/auth.scss";
import siteLogo from "../../client/images/site-logo.png";
import { signUpValidation } from "../../scripts/helpers/validationScripts";
import {
    registrationSuccess,
    registrationError,
} from "../../reducers/authSlice";
import { userSetLoading } from "../../reducers/loaderSlice";
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from "../../components/alert/CustomAlert";
import JWTService from '../../scripts/services/JWTService';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const SignupForm = () => {
    const dispatch = useDispatch();
    const [formHeight, setFormHeight] = useState(0);
    const loginFormRef = useRef(null);
    const responseSuccess = useSelector((state) => state.auth.setResponse);
    const token = JWTService.getToken();
    const [geoLocation, setGeoLocation] = useState();
    const [phoneDetails, setPhoneDetails] = useState({
        countryName: '',
        countryCode: ''
    });
    const updateFormHeight = () => {
        if (loginFormRef.current) {
            let height = loginFormRef.current.clientHeight;

            let viewportHeight = window.innerHeight;
            let totalScrollableHeight = document.body.scrollHeight;
            let max = Math.max(viewportHeight, totalScrollableHeight);
            let maximum = Math.max(max, height);
            if (max > height) {
                setFormHeight(maximum);
            } else {
                setFormHeight(height);
            }
        }
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setGeoLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
            });
        }
    }, []);

    const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {

        dispatch(userSetLoading(true));
        dispatch(registrationSuccess({}));

        try {
            values.user.countryName = phoneDetails.countryName;
            values.user.countryCode = phoneDetails.countryCode;
            values.user.latitude = geoLocation?.latitude;
            values.user.longitude = geoLocation?.longitude;

            const response = await api.post("/api/v1/auth", values);
            dispatch(
                registrationSuccess({
                    status: "success",
                    successMsg: response.data.message,
                    errorMsg: "",
                    msgState: true,
                })
            );
            resetForm();
        } catch (error) {
            if (error.response) {
                if (error.response.status === 422) {
                    setErrors(error.response.data.error);
                } else {
                    dispatch(
                        registrationError({
                            status: "error",
                            successMsg: "",
                            errorMsg:
                                error.response.data.message || error.response.data.error,
                            msgState: true,
                        })
                    );
                }
            }
        } finally {
            setSubmitting(false);
            dispatch(userSetLoading(false));
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    useEffect(() => {
        updateFormHeight();

        window.addEventListener("resize", updateFormHeight);
    }, []);

    if (window.innerWidth > 767) {
        var containerStyle = {
            height: formHeight,
        };
    }

    if (token) {
        return <Navigate to={"/dashboard"} replace />;
    }

    return (
        <div className="register-wrapper">
            <Container fluid>
                <Row>
                    <Col
                        xs={12}
                        md={6}
                        lg={6}
                        className="login-fill"
                        style={containerStyle}
                    >
                        <FeatureColumn />
                    </Col>

                    <Col xs={12} md={6} lg={6} className="form-column">
                        <div className="login-form register-form" ref={loginFormRef}>
                            <div className="form-top">
                                <Row className="align-items-center">
                                    <Col xs={7}>
                                        <Image width={200} src={siteLogo} alt="hms-logo" />
                                    </Col>
                                    <Col xs={5}>
                                        <Button
                                            className="btn-register"
                                            component={Link}
                                            href="/login"
                                        >
                                            Login
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="form-content">
                                {responseSuccess.status !== null &&
                                    responseSuccess.status !== undefined && (
                                        <CustomAlert
                                            type={responseSuccess.status}
                                            visible={true}
                                            title={
                                                responseSuccess.status === "success"
                                                    ? "Success"
                                                    : "Error"
                                            }
                                            desc={
                                                responseSuccess.status === "success"
                                                    ? responseSuccess.successMsg
                                                    : responseSuccess.errorMsg
                                            }
                                            isFixed={true}
                                        />
                                    )}
                                <h3 className="form-title">Create an account</h3>

                                <Formik
                                    initialValues={signUpValidation.initialValues}
                                    validationSchema={signUpValidation.schema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ errors, values, setFieldValue, touched }) => (
                                        <Form>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="user.first_name">First Name*</label>
                                                        <Field
                                                            type="text"
                                                            id="user.first_name"
                                                            className={`form-control ${errors?.user?.first_name &&
                                                                touched?.user?.first_name
                                                                ? "is-invalid"
                                                                : ""
                                                                }`}
                                                            name="user.first_name"
                                                        />
                                                        <ErrorMessage
                                                            name="user.first_name"
                                                            className="invalid-feedback d-block"
                                                            component="span"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="user.last_name">Last Name</label>
                                                        <Field
                                                            type="text"
                                                            id="user.last_name"
                                                            className={`form-control ${errors?.user?.last_name &&
                                                                touched?.user?.last_name
                                                                ? "is-invalid"
                                                                : ""
                                                                }`}
                                                            name="user.last_name"
                                                        />
                                                        <ErrorMessage
                                                            name="user.last_name"
                                                            className="invalid-feedback d-block"
                                                            component="span"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="user.email">Email*</label>
                                                        <Field
                                                            type="email"
                                                            id="user.email"
                                                            className={`form-control ${errors?.user?.email && touched?.user?.email
                                                                ? "is-invalid"
                                                                : ""
                                                                }`}
                                                            name="user.email"
                                                        />
                                                        <ErrorMessage
                                                            name="user.email"
                                                            className="invalid-feedback d-block"
                                                            component="span"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="user.phone_number">
                                                            Phone Number*
                                                        </label>
                                                        <PhoneInput
                                                            name="user.phone_number"
                                                            id="user.phone_number"
                                                            country={'us'}
                                                            enableSearch={true}
                                                            enableAreaCodes={true}
                                                            countryCodeEditable={false}
                                                            onChange={(value, country) => {
                                                                setPhoneDetails({
                                                                    countryName: country.name,
                                                                    countryCode: country.countryCode
                                                                });

                                                                setFieldValue('user.phone_number', value);
                                                            }}
                                                        />
                                                        <ErrorMessage
                                                            name="user.phone_number"
                                                            className="invalid-feedback d-block"
                                                            component="span"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col sm={12} md={6}>
                                                    <div className="form-group">
                                                        <label htmlFor="organization.name">
                                                            Organization Name*
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="organization.name"
                                                            className={`form-control ${errors?.organization?.name &&
                                                                touched?.organization?.name
                                                                ? "is-invalid"
                                                                : ""
                                                                }`}
                                                            name="organization.name"
                                                        />
                                                        <ErrorMessage
                                                            name="organization.name"
                                                            className="invalid-feedback d-block"
                                                            component="span"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <button type="submit" className="item-create">
                                                Create account
                                            </button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default SignupForm;
